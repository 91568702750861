.shadow-red {
  box-shadow: red;
}
.shadow-green {
  box-shadow: green;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0;
  width:100vw;
  height:100vh;
  overflow: hidden;

}

.toolbar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
}